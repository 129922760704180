import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "constants/index";
import "./style.css";

const StaticHeaderComponent = ({ routes, bottomFooter }) => {
  return (
    <div className="static-header">
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="home-page-title fw-600">
              medoc
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <div className="nav-link fw-500">
                  <a href={Routes.ABOUT_US} className="text-white">
                    About Us
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link fw-500">
                  <a href={Routes.CONTACT_US} className="text-white">
                    Contact Us
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <a href={Routes.LOGIN} target="_blank" rel="noopener noreferrer" className="btn btn-outline-light py-2 px-4">
                    Consult Now
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
