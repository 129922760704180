import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
} from "react-share";
import axios from "axios";
import $ from "jquery";
import "./style.css";

import { StaticHeaderComponent } from "components";
import { Blogs, Routes } from "constants/index";
import { add, firestore, getQuery } from "config/firebase";

const BlogTemplateComponent = (props) => {
  const [blog, setBlog] = useState("");
  const [priorityBlogs, setPriorityBlogs] = useState("");
  const [follower, setFollower] = useState("");
  const [followerLength, setFollowerLength] = useState(0);
  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const name = location.pathname.replace("/", "");

  useEffect(() => {
    window.scrollTo(0, 0);

    /* load how many people given a heart */
    async function loadBlogFollowers(n) {
      /* Evaulate based on n */
      /* To handle browser back button */
      if (!n) return null;
      setLoading(true);
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
      const blogFollowers = await getQuery(
        firestore.collection("blogFollowers").where("name", "==", n).get()
      );
      setFollowerLength(blogFollowers.length);
      const isFollower = blogFollowers.find((bf) => bf.ip === res.data.IPv4);
      if (isFollower) {
        setFollower(isFollower);
      } else {
        setFollower("");
      }
      setLoading(false);
    }

    /* load the blog which user is in view */
    const b = Blogs.find((blog) => blog.name === name);
    setBlog(b.template);
    /* Pass n to loadBlogFollowers Function */
    /* To handle browser back button */
    if (!b) {
      loadBlogFollowers(name);
    }
  }, [blog, name, priorityBlogs]);

  const redirectTo = (name) => {
    setBlog("");
    setPriorityBlogs("");
    navigate(`/${name}`);
  };

  const handleFollowerStatus = async () => {
    if (!follower) {
      const res = await add("blogFollowers", {
        name: blog.name,
        ip,
      });
      setFollower(res.data);
      setFollowerLength(followerLength + 1);
      $(`.icon-heart`).addClass("scaleInImgFollow");
    }
    setTimeout(() => {
      $(`.icon-heart`).removeClass("scaleInImgFollow");
      $(`.icon-heart`).removeClass("scaleInImgUnfollow");
    }, 7000);
  };

  const renderShareButtons = () => (
    <div className="text-center mt-3">
      <FacebookShareButton
        url={`https://medoc.life/blogs/${name}`}
        title={blog.title}
        quote={blog.category}
        hashtag="#medoc_blogs"
        className="socialMediaButton"
      >
        <FacebookIcon size={36} />
      </FacebookShareButton>
      <WhatsappShareButton
        url={`https://medoc.life/blogs/${name}`}
        title={blog.title}
        separator=" "
        className="socialMediaButton"
      >
        <WhatsappIcon size={36} />
      </WhatsappShareButton>
      <a
        href="https://www.linkedin.com/sharing/share-offsite/?mini=true&url=https://medoc.life/blogs/"
        target="_self"
        className="socialMediaButton"
      >
        <LinkedinIcon size={36} />
      </a>
    </div>
  );

  const consultNowButton = () => (
    <div className="text-center my-5">
      <a href={Routes.LOGIN} target="_blank" rel="noopener noreferrer" className="btn btn-violet-rounded px-3 text-white">
        Get Certificate Now
      </a>
    </div>
  );

  return (
    blog && (
      <>
        <Helmet>
          <title>Medoc | {blog.title}</title>
          <meta name="title" content={blog.title} />
          <meta name="description" content={blog.meta_description} />
          <meta
            name="keywords"
            content={`${blog.meta_keywords}`}
            data-react-helmet="true"
          />
          <meta name="theme-color" content="#6930db" data-react-helmet="true" />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://medoc.life/blogs/${blog.name}`}
          />
          <meta property="og:title" content={blog.title} />
          <meta property="og:description" content={blog.meta_description} />
          <meta property="og:image" content={blog.banner_url} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`https://medoc.life/blogs/${blog.name}`}
          />
          <meta property="twitter:title" content={blog.title} />
          <meta
            property="twitter:description"
            content={blog.meta_description}
          />
          <meta property="twitter:image" content={blog.banner_url} />
          <link rel="canonical" href="https://medoc.life/blogs" />
        </Helmet>
        <StaticHeaderComponent />
        <div className="container blogs-wrapper px-3 px-sm-0">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <img
                alt={blog.meta_keywords}
                className="blog-template-img pinned"
                src={blog.banner_url}
              />
            </div>
            <div className="col-12 col-sm-6 d-none d-sm-block">
              <span className="btn btn-violet blog-category">
                {blog.category}
              </span>
              <h3 className="mt-4 blog-header">{blog.title}</h3>
              <p>
                {blog.author && (
                  <small
                    dangerouslySetInnerHTML={{ __html: blog.author }}
                  ></small>
                )}
                <small>{blog.date}</small>
                <br />
              </p>
            </div>

            <div className="d-sm-none">
              <div className="mx-auto d-flex flex-column align-items-center">
                <span className="btn btn-violet blog-category  mt-3">
                  {blog.category}
                </span>
                <h3 className="mt-4 blog-header d-none d-sm-flex">
                  {blog.title}
                </h3>
                <h3 className="mt-4 blog-header d-sm-none text-center">
                  {blog.title}
                </h3>
                <p className="text-center">
                  {blog.author && (
                    <small
                      dangerouslySetInnerHTML={{ __html: blog.author }}
                    ></small>
                  )}
                  <small>{blog.date}</small>
                  <br />
                </p>
              </div>
            </div>
          </div>
          {/* for desktops */}
          <div className="row mt-5 d-none d-sm-flex">
            <div className="col-9 offset-sm-2">
              <p dangerouslySetInnerHTML={{ __html: blog.body }}></p>
              {consultNowButton()}
              <div className="follow-blog d-none" onClick={handleFollowerStatus}>
                {loading ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <div>
                    <div className="mb-1">
                      <img
                        className={`icon-heart mb-3 ${follower && "active"}`}
                        src={
                          follower ? (
                            <i className="fa fa-heart text-danger" />
                          ) : (
                            <i className="fa fa-heart" />
                          )
                        }
                        alt={blog.title}
                      />
                      <span className="follower-length">
                        {followerLength > 0 && followerLength}
                      </span>
                    </div>
                    <span className="text-violet">
                      {follower
                        ? "So sweet! Thanks for giving a heart"
                        : "Give a heart to our writer"}
                    </span>
                  </div>
                )}
              </div>
              {renderShareButtons()}
            </div>
          </div>
          {/* for desktops */}
          {/* for small devices */}
          <div className="row mobile d-sm-none">
            <div className="col-11 mx-auto">
              <p dangerouslySetInnerHTML={{ __html: blog.body }}></p>
              {consultNowButton()}
              <div className="follow-blog d-none" onClick={handleFollowerStatus}>
                {loading ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <div>
                    <div className="mb-1">
                      <img
                        className={`icon-heart mb-3 ${follower && "active"}`}
                        src={
                          follower ? (
                            <i className="fa fa-heart text-danger" />
                          ) : (
                            <i className="fa fa-heart" />
                          )
                        }
                        alt={blog.title}
                      />
                      <span className="follower-length">
                        {followerLength > 0 && followerLength}
                      </span>
                    </div>
                    <span className="text-violet">
                      {follower
                        ? "So sweet! Thanks for giving a heart"
                        : "Give a heart to our writer"}
                    </span>
                  </div>
                )}
              </div>
              {renderShareButtons()}
            </div>
          </div>
          {/* for small devices */}
          <div className="row mt-3">
            {Blogs.map((b, idx) => !b.hide && b.name !== name && idx < 5 && (
              <div className="col-12 col-sm-4 col-md-3 my-3" key={idx}>
                <div className="card pointer" onClick={e => redirectTo(b.name)}>
                  <img
                    alt={b.template.meta_keywords}
                    src={b.template.banner_url}
                    className="card-img-top blog-img"
                    width={100}
                    height={250}
                  />
                  <span className="btn btn-violet btn-sm">
                    {b.template.category}
                  </span>
                  <div className="card-body">
                    <span className="text-violet">{b.template.title.length > 63 ? <span>{b.template.title.substring(0, 63)}...</span> : b.template.title}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default BlogTemplateComponent;
