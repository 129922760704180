import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { LocalRoutes } from "routes";

import {
  LoadingComponent,
} from "components";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  return loading ? (
    <div>
      <LoadingComponent />
    </div>
  ) : (
    <div>
      <Router>
        <LocalRoutes />
      </Router>
    </div>
  );
};

export default App;

// <Route path="/" element={<HomeComponent />} />
