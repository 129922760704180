const blog = {
  name: "story-of-gopal",
  category: "Medical Certificate",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/blogs/story-of-gopal.jpg",
  title: "A short story about Gopal who need sick leave certificate in 60 minutes.",
  date: "12 Jan 2023",
  meta_description:
    "Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap.",
  canonical_link: "https://blog.medoc.life/story-of-gopal",
  meta_keywords: "short story,medical certificate,sick leave certificate,university student,sick leave",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  reading_time: "3 minutes",
  body: `
    <p>
    Gopal was a hardworking student at a prestigious university. He had always been dedicated to his studies, but one day he woke up feeling terrible. He had a fever and his throat hurt so much that he could barely speak. Despite his illness, Gopal knew that he had an important exam to take that day and a project to submit in the university. He tried to push through the pain, but it was clear that he wouldn't be able to make it to class.
    </p>
    <p>
    Gopal knew that if he wanted to take a sick leave, he would need a certificate from a doctor. He quickly got dressed and rushed to the nearest clinic, determined to get the certificate he needed. But as he walked in, he saw a long line of patients waiting to be seen. Gopal's heart sank as he realized that he only had 60 minutes to submit the certificate to the university.
    </p>
    <p>
    Feeling desperate, Gopal decided to take matters into his own hands. He asked the receptionist if there was any way to expedite the process, but she told him that all the doctors were busy and it would take at least an hour to see one. Gopal knew that he couldn't wait that long, so he decided to leave the clinic and try his luck at another one.
    </p>
    <p>
    As he walked out of the clinic, Gopal's mind raced with different possibilities. He knew that time was running out and he needed to think fast. He immediately searched online for a digital certificate that delivers in 60 minutes. He found <a href="https://medoc.life/medical-certificate" target="_blank" rel="noopener noreferrer" class="text-violet fw-600"><u>Medoc.life</u></a>. He read Medoc reviews <a href="https://www.trustpilot.com/review/medoc.life" target="_blank" rel="noopener noreferrer" class="text-violet fw-600"><u>on Trustpilot page.</u></a>
    </p>
    <p>
    Without hesitation, Gopal <a href="https://ap.medoc.life" target="_blank" rel="noopener noreferrer" class="text-violet fw-600"><u>signed up in Medoc</u></a>. He took less than five minutes to complete a short questionnaire. A registered M.B.B.S doctor contacted him in the next ten minutes to quickly confirm his details. The doctor buddy listened patiently and without hesitation, he sent digital certificate through email for Gopal and he was able to submit it in the university just in time.
    </p>
    <p>
    Gopal was relieved and grateful to the Medoc doctor for helping him out in his time of need. He knew that without the Medoc's help, he would have missed his exam and project submission. He felt lucky to have someone he could count on, even in the most difficult of situations.
    </p>
    <p>
    From that day on, Gopal made sure to always installed Medoc app, just in case he ever needed it again. He never wanted to find himself in the same predicament, but he knew that if he did, he would have a doctor buddy to turn to.
    </p>
  `,
};

export default blog;
