export const Blogs = [
  {
    name: "legit-medical-certificate",
    template: require("./blog-templates/legit-medical-certificate").default,
  },
  {
    name: "what-kind-of-medical-issues",
    template: require("./blog-templates/what-kind-of-medical-issues").default,
  },
  {
    name: "what-details-are",
    template: require("./blog-templates/what-details-are").default,
  },
  {
    name: "are-digital-medical",
    template: require("./blog-templates/are-digital-medical").default,
  },
  {
    name: "are-prescriptions-given",
    template: require("./blog-templates/are-prescriptions-given").default,
  },
  {
    name: "looking-for-sick-certificate",
    template: require("./blog-templates/looking-for-sick-certificate").default,
  },
  {
    name: "story-of-gopal",
    template: require("./blog-templates/story-of-gopal").default,
  },
  {
    name: "do-doctor-consultation",
    template: require("./blog-templates/do-doctor-consultation").default,
  },
  {
    name: "medical-letter",
    template: require("./blog-templates/medical-letter").default,
  },
  {
    name: "sick-leave-certificate",
    template: require("./blog-templates/sick-leave-certificate").default,
  },
];
