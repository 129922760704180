const blog = {
  name: "what-details-are",
  category: "Medical Certificate",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/blogs/what-details-are-mentioned.jpg",
  title: "What details are mentioned on the digital fitness certificate?",
  date: "24 Jan 2023",
  meta_description:
    "A digital fitness certificate typically includes information such as the name of the individual, the date the certificate was issued, the date the certificate expires, and the results of any fitness tests that were conducted.",
  canonical_link: "https://blog.medoc.life/what-details-are",
  meta_keywords: "fitness,certificate,organization,individual,assessments,date,gym,BMI,health,digitization",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  reading_time: "3 minutes",
  body: `
    <p>
    So, what is a digital Fitness Certificate?
    </p>
    <p>
    A digital fitness certificate is an electronic document that verifies an individual's fitness level or certifies their completion of a fitness program. It can be used for a variety of purposes, such as for personal tracking, for proof of fitness for a job, or for entry into a fitness competition. The certificate is issued by a certifying organization or individual, such as a personal trainer, gym, or health organization, and it may include information such as the individual's fitness level, the date the certificate was issued, and any relevant contact information. Digital fitness certificate is becoming more popular and common as technology and digitization are increasing in the field of health and fitness.
    </p>
    <p>
    A digital fitness certificate typically includes information such as the name of the individual, the date the certificate was issued, the date the certificate expires (if applicable), and the results of any fitness tests or assessments that were conducted. It may also include information about the individual's fitness level, such as their body mass index (BMI) or VO2 max.
    </p>
    <p>
    The certificate is issued by a certifying organization or individual, such as a personal trainer, gym, or health organization, and it may include information such as the individual's fitness level, the date the certificate was issued, and any relevant contact information. Digital fitness certificate are becoming more popular and common as the technology and digitization is increasing in the field of health and fitness.
    </p>
    <p>
    Our platform <a href='https://medoc.life' target='_blank' style='color: #6930DB !important;'>Medoc.life</a> provides valid and legally acceptable digital fitness certificates issued by licensed healthcare professionals.
    </p>
    A digital fitness certificate typically includes the following details:
    <ul>
      <li>The name of the individual who has earned the certificate</li>
      <li>The date the certificate was issued</li>
      <li>The date the certificate expires (if applicable)</li>
      <li>The name and logo of the certifying organization or individual</li>
      <li>The results of any fitness tests or assessments that were conducted, such as body mass index (BMI), VO2 max, muscular endurance and strength, cardiovascular endurance and flexibility</li>
      <li>Information about the fitness program that was completed, including the type of exercise, duration, and intensity</li>
      <li>Any relevant contact information for the certifying organization or individual, such as an email address or website</li>
      <li>A unique identification number or QR code to verify the authenticity of the certificate</li>
      <li>Digital signature or stamp from the certifying authority</li>
    </ul>
    <p>
    It's important to note that the details provided on a digital fitness certificate may vary depending on the certifying organization or individual, and the purpose of the certificate.
    </p>
  `,
};

export default blog;
