const blog = {
  name: "are-prescriptions-given",
  category: "Medical Certificate",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/blogs/are-prescriptions-given.jpg",
  title: "Are prescriptions given in online doctor consultations?",
  date: "18 Jan 2023",
  meta_description:
    "On our platform we allow our doctor buddies to diagnose, treat and prescribe medication to patients remotely through video conferencing or phone calls.",
  canonical_link: "https://blog.medoc.life/are-prescriptions-given",
  meta_keywords: "online prescription,online doctor prescription,digital prescription,india doctor prescription,doctor prescription reader online,online medical prescription",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  reading_time: "3 minutes",
  body: `
    <p>
    <b>YES</b>, you read it right! Virtual doctors or physicians can write prescriptions through telemedicine (online consultations) as long as they are licensed to practice medicine in the state or country where the patient is located and the laws and regulations of that state or country permit it. <a href='https://medoc.life' target='_blank' style='color: #6930DB !important;'>Our digital healthcare platform Medoc.life</a> administers online doctor consultations along with e-prescriptions very smoothly.
    </p>
    <p>
    Online prescriptions, also known as e-prescriptions, are prescriptions that are issued and transmitted electronically
    rather than on paper. They can be sent directly from the doctor's office to the pharmacy, eliminating the need for the
    patient to physically bring a paper prescription to the pharmacy.
    </p>
    <p>
    On our platform we allow our doctor buddies to diagnose, treat and prescribe medication to patients remotely through
    video conferencing or phone calls.
    </p>
    <p>
    To get an online prescription through digital doctor consultations :
    </p>
    <ol>
      <li>Log in to our website and navigate to the following page: <a href='https://medoc.life' target='_blank' style='color: #6930DB !important;'>Medoc - Online Doctor Consultations Across India</a></li>
      <li>Tap on <b>consult now</b> tab and fill a simple questionnaire and make the payment.</li>
    </ol>
    <p>
    Here your digital online consultation is ready!
    </p>
    <p>
    Now just, provide the doctor buddy with your medical history and any relevant information about your condition.
    </p>
    <p>
    Follow the doctor’s instructions for the consultation.
    </p>
    <p>
    If the doctor buddy determines that medication is necessary, they will write a prescription and provide it to you.
    </p>
    <p>
    Our doctor buddies ensure and maintain the safety and privacy of client/patient’s data through:
    </p>
    <ul>
      <li>
        <b>Conducting a thorough examination:</b> Our doctor buddies will use video conferencing and other technology to conduct a thorough examination of the patient, including taking vital signs and asking questions about symptoms.
      </li>
      <li>
        <b>Obtaining a patient's medical history:</b> Before the consultation, doctors will ask the patient to provide their medical history and any relevant information about their condition.
      </li>
      <li>
        <b>Following established protocols:</b> Doctors will follow established protocols for diagnosing and treating patients, as well as for prescribing medication.
      </li>
      <li>
        <b>Keeping accurate records:</b> Doctor buddies will keep accurate records of all consultations and treatment plans, which can be shared with other healthcare professionals as needed.
      </li>
      <li>
        <b>Following laws and regulations:</b> Our doctor buddies comply with the laws and regulations of the state or country where the patient is located, which include guidelines for telemedicine and prescribing medication.
      </li>
    </ul>
    <p>
    It's important to check the laws and regulations in your area, as well as the specific guidelines of the telemedicine platform or service you are using, to ensure that you are able to receive an online prescription. Keep in mind that not all prescriptions can be prescribed through telemedicine and some states may require a previous in-person visit.
    </p>
  `,
};

export default blog;
