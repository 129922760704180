const blog = {
  name: "are-digital-medical",
  category: "Medical Certificate",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/blogs/are-digital-medical2.jpg",
  title: "Are digital medical certificates acceptable in India?",
  date: "21 Jan 2023",
  meta_description:
    "As per the Telemedicine Practice Guidelines of 2020, digital medical certificates generated through online doctor consultation are considered valid and acceptable for the same purpose as traditional in-person consultation certificates.",
  canonical_link: "https://blog.medoc.life/are-digital-medical",
  meta_keywords: "online prescription,online doctor prescription,digital prescription,india doctor prescription,doctor prescription reader online,online medical prescription",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  reading_time: "3 minutes",
  body: `
    <p>
    As per the Telemedicine Practice Guidelines of 2020, issued under the provisions of the Indian Medical Council (Professional Conduct, Etiquette and Ethics) Regulations, 2002, digital medical certificates generated through telemedicine consultation are considered valid and acceptable for the same purpose as traditional in-person consultation
    certificates. So basically, what are digital medical certificates? Digital medical certificates are electronic versions of traditional paper medical certificates. They are issued by a medical professional or facility and typically contain
    information such as the patient's name, diagnosis, treatment, and any relevant medical notes. They may be in the form of a PDF document, an electronic document, or a digital image. Digital medical certificates are generally accepted in India as long as they are issued by an authorized medical professional or facility, and are in a format that is recognized and accepted by the relevant authorities.
    </p>
    <p>
    Our platform Medoc.life provides the following types of E-MEDICAL Certificates (EMCs)which are legally acceptable by different kinds of institutions and are issued by licensed professional healthcare experts.
    </p>
    <ol>
      <li>
        <b>E-SICK LEAVE CERTIFICATE:</b> These are electronic versions of traditional paper sick leave certificates, which can be generated and stored electronically. They can be easily shared and accessed by authorized individuals through secure online platforms and can be used to provide proof of illness or injury for an employer.
      </li>
      <li>
        <b>E-FITNESS CERTIFICATE:</b> These are electronic versions of traditional paper fitness certificates, which can be generated and stored electronically. They can be easily shared and accessed by authorized individuals through secure online platforms and can be used for various purposes such as for sports or fitness activities.
      </li>
      <li>
        <b>E-RECOVERY CERTIFICATE:</b> An E-Recovery Certificate is an electronic version of a traditional paper recovery certificate, which can be generated and stored electronically. It is issued by a healthcare professional or facility to indicate that a patient has recovered from an illness or injury.
        <p>
        The E-Recovery certificate typically includes the patient's name, date of recovery, and the name and signature of the issuing healthcare professional. It may also include additional information such as the diagnosis and treatment received.
        </p>
      </li>
      <li>
        <b>E-CARETAKER CERTIFICATE:</b> An E-Caretaker certificate is an electronic version of a traditional paper caretaker certificate, which can be generated and stored electronically. It is issued by a healthcare professional or facility to indicate that a person is authorized to provide care to an individual who needs assistance with their daily living activities due to an illness or injury.
        <p>
        The E-Caretaker certificate typically includes the caregiver's name, the name of the person for whom they are providing care, and the name and signature of the issuing healthcare professional. It may also include additional information such as the diagnosis and treatment received by the person who needs assistance.
        </p>
      </li>
      <li>
        <b>FORM 1A FITNESS CERTIFICATE:</b> Form 1A is a fitness certificate used in India, issued by a medical practitioner, to certify that a person is fit to perform a specific job or task. This certificate is usually issued for employment, education and other purposes.
        <p>
        The certificate typically includes the patient's name, date of examination, a brief description of the examination, and the opinion of the medical practitioner as to the patient's fitness for the job or task in question. It may also include any relevant medical information or recommendations for further examination or treatment
        </p>
      </li>
    </ul>
  `,
};

export default blog;
