const blog = {
  name: "sick-leave-certificate",
  category: "Medical Certificate",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/consultation_2.jpg",
  title: "When you need a sick leave certificate?",
  date: "18 Dec 2022",
  canonical_link: "https://blog.medoc.life/sick-leave-certificate",
  meta_description:
    "Need a sick leave certificate for work or school? Medoc makes it easy to get one fast. Simply fill out the form and receive your certificate in 60 minutes.",
  meta_keywords: "online sick leave certificate,how to get sick leave certificate,sick leave certificate for employees,sick leave certificate for school",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  reading_time: "4 minutes",
  body: `
  <div>
  <h5>Are you a student?</h5> <br />
  A college or university in India may require a sick leave certificate in order to excuse an absence due to illness. <br/><br/>

  If you're unable to attend classes due to illness, it may be necessary for you to provide a sick leave certificate in order to have their absence excused. This certificate should be issued by a qualified medical practitioner and should include information such as the student's name, the dates of the absence, and the reason for the absence. <br/><br/>

  It is important to note that the specific requirements for a sick leave certificate may vary from one college or university to another. It is always best for a student to check with your school/college/university policies and procedures to determine what is required in order to have an absence due to illness excused. <br/><br/>

  <h5>Are you an employee?</h5> <br />
  In India, a sick leave certificate is required by employers to confirm that you are absent from work due to illness. <br /><br />

  It is a way for the employer to verify that you are unable to come to work because of a legitimate reason, and it may be necessary for you to provide a sick leave certificate in order to receive any benefits or compensation for the time you are absent. <br/><br/>

  There are several reasons why an employer may require a medical certificate in India. For example, the employer may need to ensure that the employee's absence was due to a genuine medical condition and was not due to other factors such as personal or vacation leave. Additionally, the employer may need to confirm the length of the employee's absence in order to properly manage the workload of the team or to make arrangements for the employee's duties to be covered during their absence. <br/><br/>

  In general, a medical certificate should be issued by a certified doctor and should include information such as the employee's name, the dates of the absence, and the reason for the absence. The medical certificate is also used to your health insurance provider in order to receive any benefits for medical treatment. <br/><br/>
  </div>
  `,
};

export default blog;
