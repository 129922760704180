import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent } from "components";
import { Blogs } from "constants/index";

export default function Home() {
  const [blogs, setBlogs] = useState([]);
  const favicon = "https://storage.googleapis.com/medoc-live.appspot.com/images/favicon.png";

  useEffect(() => {
    let local = []
    Blogs.map(b => {
      local.push(b.template);
      setBlogs([...local]);
      return b;
    });

	}, []);

  return (
    <>
      <Helmet>
        <title>Medical Certificate Online In Minutes By Certified Indian Doctors</title>
        <meta name="description" content="Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap." />
        <meta name="keywords" content="sick leave certificate form,sick leave certificate template,sick leave certificate,how to get sick leave certificate,sick leave certificate India" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} />
        <link rel="canonical" href="https://blog.medoc.life" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Medical Certificate Online In Minutes By Certified Indian Doctors" />
        <meta property="og:description" content="Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap." />
        <meta property="og:url" content="https://blog.medoc.life" />
        <meta property="og:site_name" content="Medoc.life" />
        <meta property="og:image" content={favicon} />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2022-12-23T04:31:02+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Medical Certificate Online In Minutes By Certified Indian Doctors" />
        <meta name="twitter:description" content="Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap." />
        <meta name="twitter:image" content={favicon} />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 mins." />
      </Helmet>
      <main className="home-wrapper">
        <StaticHeaderComponent />
        <div className="container px-3 px-sm-0 mt-3 mb-5 pb-5">
  				<div className="row mt-5 pt-5">
  				{
  						blogs && blogs.map((b, idx) => {
  							return !b.hide && (
  								<div className="col-12 col-sm-4 col-md-3 my-3" key={idx}>
  									<Link to={`/${b.name}`}>
  										<div className="card">
                        <img
                          alt={b.meta_keywords}
                          src={b.banner_url}
                          className="card-img-top blog-img"
                          width={100}
                          height={250}
                        />
  											<span className="btn btn-violet btn-sm">
  												{b.category}
  											</span>
  											<div className="card-body">
													<span className="text-violet">{b.title.length > 63 ? <span>{b.title.substring(0, 63)}...</span> : b.title}</span>
  											</div>
  										</div>
  									</Link>
  								</div>
  							)
  						})
  					}
  				</div>
        </div>
      </main>
    </>
  )
}
