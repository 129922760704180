import React from "react";
import { Link } from "react-router-dom";

import { StaticHeaderComponent } from "components";
import { Routes } from "constants/index";

const NotFoundComponent = (props) => {
  return (
    <>
      <StaticHeaderComponent />
      <div className="dashboard-content pt-5 mt-5 text-center">
        <h2 className="my-5 pt-5">Oops! This page is not available.</h2>
        <p>Use the following links to securely go back</p>
        <Link to={Routes.LOGIN} className="btn btn-violet btn-sm px-3 my-2">
          {" "}
          Login to your account
        </Link>{" "}
        <br />
        <Link
          to={Routes.DOCTOR_CONSULTATION}
          className="btn btn-violet-outline text-violet my-2 btn-sm px-3"
        >
          {" "}
          Get Certificate Now{" "}
        </Link>{" "}
        <br />
        <Link
          to={Routes.MEDICAL_CERTIFICATE}
          className="btn btn-violet-outline text-violet my-2 btn-sm px-3"
        >
          {" "}
          Consult Doctor{" "}
        </Link>{" "}
        <br />
      </div>
    </>
  );
};

export default NotFoundComponent;
