import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  HomeComponent,
  NotFoundComponent,
  BlogTemplateComponent,
} from "components";

export const LocalRoutes = (props) => {
  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route
        exact
        path="/:blogName"
        element={<BlogTemplateComponent />}
      />
      <Route path="*" element={<NotFoundComponent />} />
    </Routes>
  );
};

export default Routes;
